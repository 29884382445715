/*.App {
  text-align: center;
}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  overflow-x: hidden;
}

.step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.date-step {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    width: 11%;
    color: #796c31;
    font-family:  "Titillium Web", sans-serif;
    font-weight: 500;
    border-right: 1px solid #796c31;
}

.date-step .date-step-giorno {
    font-size: 1.2em;
    line-height: 1.1;
}

.scheda-step {
    border: 0;
    border-left: 8px solid #eaf2ec;
    padding: 0 24px;
    margin-bottom: 32px;
    vertical-align: middle;
    min-height: 80px;
    -webkit-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.1);
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    font-size: 1em;
    display: table;
    width: 100%;
    background: #fff;
    margin-left:  20px;
}

.scheda-step-testo {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    font-family: "Titillium Web",Geneva,Tahoma,sans-serif!important;
    font-size: .889em;
    padding: 16px 0;
}

.step .date-step .pallino {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 35px;
    right: -5px;
    background-color: #796c31;
    border-radius: 50%;
}

.step .date-step:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 40px;
    right: -5px;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    border-radius: 50%;
    background-color: #796c31;
}

.service-sidebar-menu {
  border-right: 1px solid #ebebeb;
  padding-right: 20px !important;
}

.service-container {
  border-top:  1px solid #ebebeb;
}

.sticky-wrapper.navbar-wrapper {
  position: sticky;
  top: 0;
}

.service-content-title, .service-content-list, .service-content-link, .service-content-description, .service-content-btn, .service-office-name, .service-read-more {
  font-family:  "Titillium Web", sans-serif;
}

.btn-access {
    color: #fff !important;
    background-color: #008758 !important;
    border-color: #008758 !important;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%) !important;
    padding: 14px 28px !important;
    font-size: 18px !important;
    line-height: 1.6 !important;
}

.poweredby {
  font-size:  15px;
  padding: 1.5rem 1rem;
  font-family:  "Titillium Web", sans-serif;
}